<template>
  <div>
    <div class="top">
      <!-- <img src="@/assets/introduce/i1.png" /> -->
      <div class="top-box">
        <div class="left">
          <img src="@/assets/contact/contactqx.png" alt="我们的地址" />
        </div>
        <div class="right">
          <div class="right-title">东莞公司</div>
          <div class="right-text">
            <p>公司地址：广东省东莞市南城区天安数码城B2幢607号</p>
            <p>联系QQ：3352825133</p>
            <p>市场合作电话号码：+86 18525479522</p>
            <p>工作时间：周一到周五8:30-17:30</p>
            <p>
              投诉邮箱：<a href="mailto:xiaomeikeji@mail.chat5188.com"
                >xiaomeikeji@mail.chat5188.com</a
              >
            </p>
          </div>
          <div>
            <div class="end">
              <div class="right-end">
                <img src="@/assets/up/wecatCode.png" alt="企业微信" />
                <p>微信二维码</p>
              </div>
              <!-- <div class="right-end">
                <img src="@/assets/up/wecatCode.png" alt="企业微信" />
                <p>二维码</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "联系我们 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,小美客服、小美客服系统、小美客服软件、客服系统、客服软件、智能客服系统、智能客服软件、智能机器人、工单系统、在线客服",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
};
</script>

<style scoped lang="less">
.top {
  height: 1031px;
  width: 100%;
  position: relative;
  background-image: url("@/assets/price/base.png");
  background-size: 100% 100%;
  @media screen and (max-width: 700px) {
    height: 1140px;
  }
}

.top img {
  width: 100%;
  height: 432px;

  @media screen and (max-width: 700px) {
    height: 200px;
  }
}

.top-box {
  width: 1100px;
  height: 553px;
  padding: 50px;
  background-color: #fff;
  position: absolute;
  top: 216px;
  left: 50%;
  margin-left: -600px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    width: 380px;
    height: 920px;
    padding: 20px;
    top: 110px;
    flex-direction: column;
    margin-left: -210px;
  }
}

.left {
  width: 100%;
  height: 100%;
  padding: 0 20px;

  @media screen and (max-width: 700px) {
    height: 440px;
  }
}

.left img {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 700px) {
    height: 340px;
    width: 340px;
  }
}

.right {
  width: 100%;
  padding: 0 20px;
  height: 100%;
}

.end {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}

.right-end {
  margin: 40px 20px 0 0;
}

.right-end img {
  width: 134px;
  height: 134px;
}

.right-title {
  color: #021b64;
  font-size: 30px;
  margin-top: 10px;
}

.right-text {
  color: #021b64;
  font-size: 18px;
  margin-top: 30px;
  opacity: 0.7;
}

.right-text p {
  margin-top: 10px;
}
</style>
