<template>
  <div class="contain">
    <!-- <div class="dialog">
      <dialog-vue />
    </div> -->
    <div class="up">
      <up-vue />
    </div>
    <div class="header">
      <div class="menu">
        <div style="width: 100%">
          <el-menu
            :unique-opened="true"
            router
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
          >
            <span class="logo">
              <img src="@/assets/HeaderList/logo.png" alt="小美智能云服" />
            </span>
            <span class="profile"> 小美智能云服 </span>
            <el-menu-item index="/home">首页</el-menu-item>
            <el-sub-menu index="1">
              <template #title>产品</template>
              <el-menu-item index="/customer">
                <i class="iconfont icon-zaixiankefu"></i>
                <span class="span">在线客服</span>
              </el-menu-item>
              <el-menu-item index="/workorder">
                <i class="iconfont icon-zu1778"></i>
                <span class="span">工单系统</span>
              </el-menu-item>
              <el-menu-item index="/robot">
                <i class="iconfont icon-jiqiren1"></i>
                <span class="span">智能机器人</span>
              </el-menu-item>
              <el-sub-menu index="1-1">
                <template #title>
                  <i class="iconfont icon-disanfangyingyong"></i>
                  <span class="span">第三方客服服务</span>
                </template>
                <el-menu-item index="/Applet">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-xiaochengxu1"></i>
                  <span class="span">小程序</span>
                </el-menu-item>
                <el-menu-item index="/web">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-program-full"></i>
                  <span class="span">网页客服</span>
                </el-menu-item>
                <el-menu-item index="/wechat">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-qiyeweixin"></i>
                  <span class="span">企业微信</span>
                </el-menu-item>
                <el-menu-item index="/app">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-app"></i>
                  <span class="span">app</span>
                </el-menu-item>
                <el-menu-item index="/Tiktok">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-douyintexiao"></i>
                  <span class="span">抖音</span>
                </el-menu-item>
                <el-menu-item index="/official">
                  <!-- <el-menu-item index=""> -->
                  <i class="iconfont icon-gongzhonghao1"></i>
                  <span class="span">公众号</span>
                </el-menu-item>
              </el-sub-menu>
            </el-sub-menu>
            <el-menu-item index="/price">价格</el-menu-item>
            <el-sub-menu index="2">
              <template #title>服务与支持</template>
              <!-- <el-menu-item index="/question"> -->
              <el-menu-item index="">
                <i class="iconfont icon-a-changjianwentixiangguanwenti"></i>
                <span class="span">常见问题</span>
              </el-menu-item>
              <el-menu-item index="/statement">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-declare"></i>
                <span class="span">隐私声明</span>
              </el-menu-item>
              <el-menu-item index="/agreement">
                <i class="iconfont icon-navicon-yhxy"></i>
                <span class="span">用户协议</span>
              </el-menu-item>
              <el-menu-item index="/developer">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-kaifazhezhongxinSDKxiazai"></i>
                <span class="span">开发者中心</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3">
              <template #title>下载</template>
              <el-menu-item index="/center">
                <i class="iconfont icon-xiazaizhongxin"></i>
                <span class="span">下载中心</span>
              </el-menu-item>
              <el-menu-item index="/journal">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-server-update-full"></i>
                <span class="span">更新日志</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4">
              <template #title>关于我们</template>
              <el-menu-item index="/introduce">
                <i class="iconfont icon-gongsijieshao-01"></i>
                <span class="span">公司介绍</span>
              </el-menu-item>
              <el-menu-item index="/honor">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-rongyu"></i>
                <span class="span">资质荣誉</span>
              </el-menu-item>
              <el-menu-item index="/contact">
                <i class="iconfont icon-lianxiwomen1"></i>
                <span class="span">联系我们</span>
              </el-menu-item>
              <el-menu-item index="/join">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-jiaruwomen"></i>
                <span class="span">加入我们</span>
              </el-menu-item>
              <!-- <el-menu-item index="/quotation"> -->
              <el-menu-item index="">
                <i class="iconfont icon-xinwenzixun-xianxing"></i>
                <span class="span">行情资讯</span>
              </el-menu-item>
            </el-sub-menu>
            <div class="other">
              <el-menu-item>
                <el-link
                  href="https://regist.chat5188.com/"
                  :underline="false"
                  target="_blank"
                  class="logn"
                >
                  登录/注册
                </el-link>
              </el-menu-item>
            </div>
          </el-menu>
        </div>
      </div>
      <div id="noticeId1" class="notice-pc notice-content">
        <div class="notice-body">
          在使用小美企业客服软件期间，不得危害国家安全、泄露国家秘密，不得侵犯国家社会集体和公民的合法权益，本产品禁止用于含木马、病毒、色情、赌博、诈骗等违法违规业务。小美科技公司对违反规定的可以暂停或终止其服务，并协助有关行政机关等进行追索和查处。投诉举报电话：18525479522
          <!-- 近期发现不法分子冒充小美官方，在telegram软件注册并建立聊天群和频道进行违法犯罪和诈骗，目前发现已知冒充官方的telegram账号有：@chat5188 、
          @chat51888  、@chat5188vip 、 @xiaomeichats
          以及对应的telegra群和频道。如有新发现欢迎及时举报！ -->
        </div>
        <div class="notice-close">
          <el-button
            type="primary"
            style="margin-left: 20px"
            onclick="getElementById('noticeId1').classList.add('hide')"
          >
            好的
          </el-button>
          <!-- <img
            onclick="javascript:document.getElementById('noticeId1').classList.add('hide');"
            src="/static/images/close.png"
            style="width: 16px; height: 16px"
          /> -->
        </div>
      </div>
      <!-- <div id="noticeId2" class="notice-pc notice-content">
        <div class="notice-body">
          近期发现不法分子冒充小美官方，在telegram软件注册并建立聊天群和频道进行违法犯罪和诈骗，目前发现已知冒充官方的telegram账号有：@chat5188 、
          @chat51888  、@chat5188vip 、 @xiaomeichats
          以及对应的telegra群和频道。如有新发现欢迎及时举报！
        </div>
        <div class="notice-close">
          <button
            onclick="javascript:document.getElementById('noticeId2').classList.add('hide');"
            class="notice-button"
          >
            好的
          </button>
        </div>
      </div> -->

      <div class="pc">
        <div class="logo">
          <img src="@/assets/HeaderList/logo.png" alt="小美智能云服" />
        </div>
        <span class="profile"> 小美智能云服 </span>
        <div class="pc-right" @click="drawer = true">
          <img src="@/assets/HeaderList/icon-liebiao.png" alt="更多" />
        </div>
        <!--  -->
        <el-drawer v-model="drawer" direction="ttb" :with-header="false">
          <el-menu
            :unique-opened="true"
            router
            :default-active="activeIndex"
            class="el-menu-demo"
          >
            <el-menu-item @click="menuClick" index="/home">
              <span class="span">首页</span>
            </el-menu-item>
            <el-sub-menu index="1">
              <template #title>
                <span class="span">产品</span>
              </template>
              <el-menu-item @click="menuClick" index="/customer">
                <i class="iconfont icon-zaixiankefu"></i>
                <span class="span">在线客服</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/workorder">
                <i class="iconfont icon-zu1778"></i>
                <span class="span">工单系统</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/robot">
                <i class="iconfont icon-jiqiren1"></i>
                <span class="span">智能机器人</span>
              </el-menu-item>
              <el-sub-menu index="1-1">
                <template #title>
                  <i class="iconfont icon-disanfangyingyong"></i>
                  <span class="span">第三方客服服务</span>
                </template>
                <!--<el-menu-item @click="menuClick" index="/Applet">-->
                <el-menu-item index="">
                  <i class="iconfont icon-xiaochengxu1"></i>
                  <span class="span">小程序</span>
                </el-menu-item>
                <!--<el-menu-item @click="menuClick" index="/web">-->
                <el-menu-item index="">
                  <i class="iconfont icon-program-full"></i>
                  <span class="span">网页客服</span>
                </el-menu-item>
                <!--<el-menu-item @click="menuClick" index="/wechat">-->
                <el-menu-item index="">
                  <i class="iconfont icon-qiyeweixin"></i>
                  <span class="span">企业微信</span>
                </el-menu-item>
                <!--<el-menu-item @click="menuClick" index="/app">-->
                <el-menu-item index="">
                  <i class="iconfont icon-app"></i>
                  <span class="span">app</span>
                </el-menu-item>
                <!--<el-menu-item @click="menuClick" index="/Tiktok">-->
                <el-menu-item index="">
                  <i class="iconfont icon-douyintexiao"></i>
                  <span class="span">抖音</span>
                </el-menu-item>
                <!--<el-menu-item @click="menuClick" index="/official">-->
                <el-menu-item index="">
                  <i class="iconfont icon-gongzhonghao1"></i>
                  <span class="span">公众号</span>
                </el-menu-item>
              </el-sub-menu>
            </el-sub-menu>
            <el-menu-item @click="menuClick" index="/price">
              <span class="span">价格</span>
            </el-menu-item>
            <el-sub-menu index="2">
              <template #title>
                <span class="span">服务与支持</span>
              </template>
              <!--<el-menu-item @click="menuClick" index="/question">-->
              <el-menu-item index="">
                <i class="iconfont icon-a-changjianwentixiangguanwenti"></i>
                <span class="span">常见问题</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/statement">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-declare"></i>
                <span class="span">隐私声明</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/agreement">
                <i class="iconfont icon-navicon-yhxy"></i>
                <span class="span">用户协议</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/developer">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-kaifazhezhongxinSDKxiazai"></i>
                <span class="span">开发者中心</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3">
              <template #title>
                <span class="span">下载</span>
              </template>
              <el-menu-item @click="menuClick" index="/center">
                <i class="iconfont icon-xiazaizhongxin"></i>
                <span class="span">下载中心</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/journal">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-server-update-full"></i>
                <span class="span">更新日志</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4">
              <template #title>
                <span class="span">关于我们</span>
              </template>
              <el-menu-item @click="menuClick" index="/introduce">
                <i class="iconfont icon-gongsijieshao-01"></i>
                <span class="span">公司介绍</span>
              </el-menu-item>
              <!--<el-menu-item @click="menuClick" index="/honor">-->
              <el-menu-item index="">
                <i class="iconfont icon-rongyu"></i>
                <span class="span">资质荣誉</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/contact">
                <i class="iconfont icon-lianxiwomen1"></i>
                <span class="span">联系我们</span>
              </el-menu-item>
              <el-menu-item @click="menuClick" index="/join">
                <!-- <el-menu-item index=""> -->
                <i class="iconfont icon-jiaruwomen"></i>
                <span class="span">加入我们</span>
              </el-menu-item>
              <!--<el-menu-item @click="menuClick" index="/quotation">-->
              <el-menu-item index="">
                <i class="iconfont icon-xinwenzixun-xianxing"></i>
                <span class="span">行情资讯</span>
              </el-menu-item>
            </el-sub-menu>
            <!-- <el-menu-item index="5">
              <span class="logn">登录/注册</span>
            </el-menu-item> -->
          </el-menu>
        </el-drawer>
      </div>
      <div id="noticeId3" class="notice-mobile notice-content">
        <div class="notice-body">
          在使用小美企业客服软件期间，不得危害国家安全、泄露国家秘密，不得侵犯国家社会集体和公民的合法权益，本产品禁止用于含木马、病毒、色情、赌博、诈骗等违法违规业务。小美科技公司对违反规定的可以暂停或终止其服务，并协助有关行政机关等进行追索和查处。投诉举报电话：18525479522
        </div>
        <div class="notice-close">
          <el-button
            type="primary"
            style="margin-left: 20px"
            onclick="getElementById('noticeId3').classList.add('hide')"
          >
            好的
          </el-button>
        </div>
      </div>
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="main">
            <router-view />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <FootList />
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import DialogVue from "../components/dialog";
import UpVue from "../components/up.vue";
import FootList from "./footList.vue";

import { useRoute } from "vue-router";
import { ref, watch } from "vue";
const route = useRoute();
const activeIndex = ref(route.path);
watch(
  () => route,
  (to, from) => {
    activeIndex.value = to.path;
  },
  {
    immediate: true,
    deep: true,
  }
);
const drawer = ref(false);
const menuClick = () => {
  drawer.value = false;
};
</script>

<style scoped lang="less">
.iconfont {
  font-size: 20px;
}

.hide,
HIDE {
  display: none !important;
}
.notice-content {
  // background-color: #fff;
  background-color: #f4fcff;
  display: flex;
  padding: 10px 60px;
  margin: 1px 0px;
  @media screen and (max-width: 700px) {
    padding: 6px 10px;
  }
}
.notice-pc {
  @media screen and (max-width: 700px) {
    display: none;
  }
}
.notice-mobile {
  display: none;
  @media screen and (max-width: 700px) {
    display: flex;
  }
}
.notice-body {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #021761;
  font-weight: 400;
  margin: 0 6px;
}
.notice-button {
  margin-left: 20px;
  width: 64px;
  height: 28px;
  border: none;
  text-align: center;
  line-height: 28px;
  background-image: linear-gradient(
    159deg,
    #99e1ff 0%,
    #74d9ff 97%,
    #74d9ff 97%
  );
  border-radius: 6px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    width: 44px;
    height: 18px;
    border: none;
    text-align: center;
    line-height: 18px;
    margin-left: 10px;
    font-size: 8px;
  }
}
.notice-close {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notice-close:hover {
  opacity: 0.6;
}

.up {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.pc {
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.pc-right {
  @media screen and (max-width: 700px) {
    margin-right: 20px;
    margin-top: 10px;
  }
}

::v-deep .el-drawer.ttb {
  top: 64px;
  height: 380px !important;
}

::v-deep .el-drawer__body {
  height: 380px !important;
}

.pc-right img {
  @media screen and (max-width: 700px) {
    width: 45px;
    height: 45px;
  }
}

.menu {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.logo {
  line-height: 64px;
  color: #fff;
  // margin-right: 380px;
  margin-right: 25px;
  margin-left: 70px;
  margin-top: 6px;
  outline: none;
  border: none;

  @media screen and (max-width: 700px) {
    margin-right: 0px;
    margin-left: 35px;
  }
}

.logo img {
  width: 50px;
  height: 50px;
}
.profile {
  display: block;
  // width: 200px;
  padding-right: 150px;
  padding-left: 20px;
  margin-top: 6px;
  color: #f4fcff;
  line-height: 55px;
  font-family: PingFangSC-Regular;
  font-size: 26px;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    display: block;
    // width: 120px;
    padding-right: 90px;
    padding-left: 0px;
    margin-top: 6px;
    color: #f4fcff;
    line-height: 55px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    font-weight: 400;
  }
}
.logn {
  display: inline-block;
  width: 112.8px;
  height: 32.4px;
  border: 1px solid #fff;
  line-height: 32.4px;
  text-align: center;
  border-radius: 4.8px;
  color: #fff;
  margin-top: 8px;
  background: #353e67;
}

.logn:hover {
  border: 1px solid #78d9ff;
  color: #fff;
  background-color: #78d9ff;
}

.main {
  background-color: #f4f7ff;
  margin-top: 64px;
}

.contain {
  width: 100%;
}

.header {
  background: -webkit-linear-gradient(
    left,
    rgba(0, 38, 154, 0.81),
    rgba(2, 12, 47, 0.81)
  );
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 700px) {
    position: absolute;
  }
}

.span {
  margin-left: 10px;
  font-size: 18px;
}

/* 鼠标悬浮时，子菜单的样式 */
.el-menu-item:hover {
  background-color: #fff !important;
  color: rgb(80, 166, 255) !important;
}

::v-deep .el-sub-menu__title.el-tooltip__trigger.el-tooltip__trigger:hover {
  background-color: #fff !important;
  color: rgb(80, 166, 255) !important;
}

.other .el-menu-item:last-child:hover {
  background: transparent !important;
  color: #fff !important;
  line-height: 64px;
}

.logn:hover {
  border: 1px solid #78d9ff;
  color: #fff;
  background-color: #78d9ff;
}

::v-deep .el-menu {
  width: 100%;
  background-color: transparent;
  border: none;
}
::v-deep .el-menu--horizontal.el-menu {
  border-bottom: none;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  color: #fff;
  font-size: 18px;
  height: 64px;
}

::v-deep .el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  font-size: 18px;
  color: #fff;
  height: 64px;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  color: #333333 !important;
  background-color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep .el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  color: #333;
  background-color: #fff !important;
  border-bottom: 2px solid #fff;
}
::v-deep .el-col {
  padding: 0 !important;
}
</style>
