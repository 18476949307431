<template>
  <div class="end">
    <div class="end-box-agr">
      <div class="meun">
        <el-menu
          :default-active="index.toString()"
          background-color="#fff"
          text-color="#333"
          active-text-color="#fff"
        >
          <el-menu-item
            @click="menuItemClick(index)"
            v-for="(list, index) in leftList"
            :key="index"
            :index="index.toString()"
          >
            <span slot="title">{{ list.label }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="content">
        <div class="box" v-if="index === 0">
          <div class="content-title">小美客服系统终止服务流程规则</div>
          <div class="content-text">
            <div class="short-space"></div>
            若您在小美客服系统服务期届满前要求终止服务的，需遵循以下办理流程，小美网络公司将依据以下流程及要求确认您是否满足终止服务及退款的条件。
          </div>
          <div class="little margin-title">
            第1条
            <div class="shore-space-between"></div>
            退款说明
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.
            退款时小美客服系统只向您退还实际未消耗的剩余服务费，赠送服务期和现金优惠金额等不计入退款结算额中。退款计算公式为：用户实际支付金额-用户购买服务产品的标准售价/对应服务产品用户实际应开通的服务天数*对应服务产品用户已使用的服务天数。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2. 以下情况不支持退款：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            a. 您未遵守退款流程或者退款材料缺失；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            b.
            您购买的是短信、话费、机器人会话包/会话条数等消耗类产品，网关、耳麦等硬件设备，实施定制产品、增值服务类产品、小美私有化产品或订单页中约定不可退款的产品/服务；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            c. 小美客服系统未违反与您达成的相关约定的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            d.
            因您自身原因违反与小美客服系统签署的协议约定（含协议附件约定）、《小美客服系统服务条款》或您违反国家相关法律规定导致小美客服系统无法提供服务的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            e. 无正当理由单方面解除与小美客服系统签署的协议。
          </div>
          <div class="little margin-title">
            第2条
            <div class="shore-space-between"></div>
            终止协议办理流程
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.
            您需提前一个月向您的专属小美客服系统顾问（或小美客服系统服务合同中约定的联系人）发送终止服务申请邮件并说明终止原因。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2. 小美网络公司将在接收申请的15个工作日内回复是否同意终止服务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3. 若小美网络公司同意终止服务，则您应当提供如下材料申请费用结算：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            a. 发票所有联（如已开具发票）；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            b. 加盖公章的情况说明；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            c.
            若小美客服系统已开具发票为增值税专用发票，您需在您所属的税务局开通红字发票通知单（如已认证）；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            d. 加盖公章的付款凭证。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.
            小美网络公司收到前述完整材料后，与您协商办理终止服务和费用结算事宜。
          </div>
        </div>
        <div class="box" v-if="index === 1">
          <div class="content-title">小美客服系统服务条款</div>
          <div class="content-text">
            <div class="short-space"></div>
            欢迎使用由小美网络公司提供的小美客服系统服务。
            <span class="bold">
              在您接受本服务条款或使用小美客服系统服务之前，请仔细阅读并充分理解以下全部内容（其中限制或免除责任条款，以及其他涉及您重大权益的条款将会以加粗等形式提示您重点注意）。如果您系未满十八周岁的未成年人，则应在法定监护人陪同下阅读。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            如您对本服务条款有疑问的，请通过小美客服系统网站公布的联系方式进行询问，小美网路公司将向您解释条款内容。如您不接受本服务条款，或者无法准确理解小美网络公司对条款内容的解释，请不要注册、购买、开通或以其他方式使用小美客服系统服务。
          </div>
          <div class="little margin-title">1、服务条款的确认和接纳</div>
          <div class="content-text">
            <div class="short-space"></div>
            1.1
            本服务条款内容包括条款正文、本服务条款明确援引的其他协议、小美客服系统服务已经发布的或将来可能发布的单项服务条款/说明/公告/标准（如有，以下统称为“服务规则”），以及您确认同意的订购页面。所有内容为本服务条款不可分割的组成部分，与条款正文具有同等效力。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            1.2
            如您选择以页面确认或其他任何方式明示或默示表示接受本服务条款，或您以注册、购买、开通等方式使用小美客服系统服务，即表示您与小美网络公司已达成协议，自愿接受本服务条款的全部内容，您使用小美客服系统服务的行为都应当受本服务条款约束。除另有明确约定外，如您与小美网络公司另行签署电子签名合同或纸质盖章合同的，相关文本与本服务条款有不一致之处，以双方电子签名或纸质盖章文本为准。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.3
            本服务条款如由于业务发展或其他原因需要进行修订的，小美网络公司将在小美客服系统网站或其他官方平台公布修订版本，您可前往查阅最新版条款内容。在小美网络公司修改上述条款后，如果您不接受修改后的条款，您可以选择终止使用小美客服系统服务。您继续使用的行为将被视为已接受了修改后的条款。
          </div>
          <div class="little margin-title">2、帐号注册及使用规则</div>
          <div class="content-text">
            <div class="short-space"></div>
            2.1
            您可浏览小美客服系统网站上的产品或服务介绍，如您希望试用、购买小美客服系统服务，您需先登录您的帐号，或注册小美网络公司认可的帐号并登录。您注册登录的帐号是小美网络公司确认您身份的唯一依据。小美客服系统帐号注册资料包括但不限于您的帐号名称、头像、密码、联系方式、提交供实名认证的资料、应用产品信息，以及其他注册或更新小美客服系统帐号、创建应用产品时输入的所有信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.2
            您在注册小美客服系统帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在小美客服系统帐号注册资料中出现不实、违法和不良信息。且您保证在注册和使用帐号时，不得有以下情形：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.1 违反宪法或法律法规规定的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.3 损害国家荣誉和利益的，损害公共利益的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.4
            歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.5 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.6 煽动民族仇恨、民族歧视，破坏民族团结的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.7 破坏国家宗教政策，宣扬邪教和封建迷信的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.8 散布谣言，扰乱社会秩序，破坏社会稳定的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.9 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.10 侮辱或者诽谤他人，侵害他人合法权益的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2.2.11 含有法律、行政法规禁止的其他内容的。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.3
            注册完成后，请您妥善保存有关帐号和密码，并对该帐号进行的所有活动和行为负责。
            <span class="bold">
              如因您自身原因（包括但不限于转让帐号、与他人共用、自己泄露等）或您所用计算机或其他终端产品感染病毒或木马，而导致帐号密码泄漏、遗失或其他损失后果将由您独自承担。
            </span>
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.4
            小美网络公司有权对您提供的账号注册资料进行审查，若发现或者收到举报确认注册资料存在不准确、不真实或含有违法、不良信息，小美网络公司有权不予注册，并保留终止您使用小美客服系统服务的权利。若您以虚假信息骗取账号注册或账号注册资料存在侵权、违法和不良信息的，小美网络公司有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用其他机构或他人名义注册账号名称的，小美网络公司有权注销该账号，并向政府主管部门进行报告。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.5
            您若发现自身账号或密码被他人非法使用或有登录、使用异常情况的，应及时通知小美网络公司，小美网络公司将按照本服务条款或法律规定进行处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            2.6
            在法律法规允许的范围内，您同意小美客服系统账号所有权，以及与注册、使用小美客服系统服务的服务数据和记录，包括但不限于所有注册、登录、消费记录、客户服务记录和相关的使用统计数据，归小美网络公司所有。发生争议时，您同意以小美网络公司的系统数据为准，小美网络公司保证该数据的真实性。
          </div>
          <div class="little margin-title">3、服务的开通</div>
          <div class="content-text">
            <div class="short-space"></div>
            3.1
            您可根据自身需求，自行通过小美客服系统网站在线选购所需服务，购买前您需仔细阅读所购服务项目对应的服务规则并决定是否购买或使用。您如需签订正式书面合同的，您可以直接按照小美客服官网网站公布的指引申请签订电子签名合同，也可以通过小美客服系统网站公布的方式与小美网络公司联系，小美网络公司可根据实际合作需要，与您另行接洽并签订纸质盖章合同。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.2
            您需在订单提交后及时完成支付。部分服务可能有时间限制、库存限制或者活动数量等限制，订单提交后，若发生您未及时付款、或付款期间出现数量不足或已达到限量等情况的，您将可能无法使用相关服务。
          </div>
          <div class="little margin-title">4、服务使用规则</div>
          <div class="content-text">
            <div class="short-space"></div>
            4.1
            您有权选择并获得小美网络公司提供的小美客服系统服务、售后服务和相关技术支持。
            <span class="bold">
              除非另有证明，小美网络公司储存在其服务器上的数据是您使用小美客服系统服务的唯一有效证据。
            </span>
            若您使用的是具有收费内容的小美客服系统服务，应按照小美客服系统网站的页面提示及本服务条款的约定支付相应的服务费用。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.2
            您应当按照小美客服系统网站上展示的相应服务说明、技术规范、使用流程、操作文档等内容确定的操作指引使用小美客服系统服务。在使用过程中，对于您违反相关操作指引所引起的后果将由您自行承担，小美网络公司不承担任何责任。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.3
            您应当依照《互联网信息服务管理办法》等相关法律法规的规定时限保留您使用小美客服系统服务的应用产品的信息内容及其发布时间、互联网地址（IP）、域名等，您应自行承担未按规定保留相关记录而引起的全部法律后果和责任。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.4
            您应遵守相关法律法规政策及服务规则，维护互联网秩序和安全，同时，您不得利用小美客服系统服务，实施任何违反国家法律法规政策的行为，也不得为任何违反法律法规政策的行为提供便利：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.1 违反宪法或法律法规政策规定的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.3 损害国家荣誉和利益的，损害公共利益的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.4
            歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.5 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.6 煽动民族仇恨、民族歧视，破坏民族团结的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.7 破坏国家宗教政策，宣扬邪教和封建迷信的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.8 散布谣言，扰乱社会秩序，破坏社会稳定的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.9 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.10 侮辱或者诽谤他人，侵害他人合法权益的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.11 以非法民间组织名义活动的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.12
            可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等损害未成年人身心健康的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.13
            散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.14 所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.4.15 含有法律、行政法规禁止或其他违反社会公序良俗的内容的。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.5
            您不得利用小美客服系统服务进行侵害小美网络公司或他人的知识产权或者合法利益的行为，包括但不限于：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.1
            您不得修改、翻译、出版、改编、出租、转许可、在信息网络上传播或转让小美客服系统服务或软件，也不得逆向工程、反编译或以其他方式发现小美客服系统服务的任何源代码；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.2
            您不得进行任何改变或试图改变小美客服系统服务提供的系统配置或破坏小美网络公司系统安全的行为；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.3
            未经小美网络公司的另行授权，您不得向任何第三方以任何形式提供本服务条款项下服务，包括但不限于转售、转租或转赠小美客服系统服务；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.4
            您不应进行其他超出小美网络公司提供的服务范围，可能给小美网络公司带来任何不利影响的行为或者是国家禁止的行为；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.5
            您不得故意避开或者破坏著作权人为保护小美客服系统服务相关软件、代码著作权而采取的技术措施,或实施侵害他人知识产权等任何合法权益的行为，比如私服、外挂等；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4.5.6
            您不得进行任何破坏或试图破坏网络安全的行为,包括但不限于通过钓鱼、病毒、木马、恶意代码等方式，对网站进行恶意扫描、非法侵入系统、非法获取数据等。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.6
            如您通过小美客服系统服务进行经营或者非经营性活动需要获得国家有关部门许可或批准的，应当获得有关的许可或批准。如您未办理必要审批而开展此类业务的，小美网络公司有权中止或终止向您提供服务而无需承担任何责任。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.7
            您知悉并理解，小美网络公司有权在小美客服系统服务过程中或经过小美客服系统服务向您投放各种广告和宣传信息。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.8
            对于您利用小美客服系统服务所发布、传输、存储的信息，小美网络公司保留依据国家相关法律法规对其通讯的信息进行关键词过滤的权利，如发现您发送内容明确存在违反国家相关法律法规的，小美网络公司有权做出包括但不限于劝阻、拦截、直至向有关公安部门举报等行为。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.9
            小美网络公司委托的第三方有权对您提供的应用产品及内容进行审核，包括但不限于内容审查、功能性测试、安全性测试等。如果发现您的应用产品不符合国家法律法规、政策规定，或您提供的应用产品可能侵犯他人合法权益或含有对其他第三方的广告信息等内容，或其他认为不符合要求的情况，小美网络公司有权不向您提供服务；已提供服务的，小美网络公司有权立即停止继续向您提供服务，并保存有关记录向相关主管部门报告。但是，该项约定不视为小美网络公司对您提供的应用产品提供合法性担保，您应自行对应用产品提供保证，并承担由此引发的所有责任。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.10
            若小美网络公司发现您使用小美客服系统服务的应用产品出现请求异常的，存在遭受包括但不限于DDOS等网络攻击的可能的，为维护您的应用产品及小美客服系统服务的安全与稳定，小美网络公司有权根据自己的判断对您的应用产品采取限制流量、中止服务等紧急措施，并通知您。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            4.11
            如您违反本服务条款的约定或法律法规的规定，不论系小美网络公司自行发现或根据主管部门的信息、第三方机构或个人对您提出质疑或投诉等发现，小美网络公司有权作出独立判断并采取包括但不限于以下一种或多种处理措施：
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            4.11.1
            要求您立即删除、修改相关内容，或直接删除、屏蔽、断开相关内容；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            4.11.2
            立即限制、暂停对您提供部分或全部服务，包括冻结、取消您的账号的使用权限、收回全部服务资源等；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            4.11.3
            您未使用的费用将直接作为违约金归小美网络公司所有，前述违约金不足弥补小美网络公司损失的，有权要求您继续承担赔偿责任；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            4.11.4 终止对您提供小美客服系统服务，并且不退还您未使用的费用；
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            4.11.5 依法追究您的其他责任。
          </div>
          <div class="little margin-title">5、小美客服系统的服务费用</div>
          <div class="content-text">
            <div class="short-space"></div>
            5.1
            小美客服系统服务的计费方式及价格等信息，以小美客服系统各具体服务项目网页公布的计费和价格信息为准。您可根据自身需求，自行选择具体服务类型并按照网站列明的价格进行支付或按照双方另行商定的价格订立合同、进行支付。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.2
            您在登录小美客服系统平台（包括但不限于小美客服系统网站）后，可以通过管理后台进行查看账户信息、提交订单、开通、中止或终止服务项目、充值及查看账单等操作。对于先充值后使用的服务，您应保持账户余额充足以确保服务的持续使用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.3
            为保证服务及时开通或持续提供，您应当遵守本服务条款及您使用的具体服务项目的服务规则并及时缴纳费用。在您提交订单并在付费后方可享受小美客服系统服务。如您提交订单后30个自然日内未付款的，该订单将自始无效，亦即您和小美网络公司就该订单所载明的服务内容及价格内容所达成的一切合意或行为均失效。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.4
            除非另有约定，您如需就支付的费用开具发票的，需通过向客服邮箱发送电子邮件的形式提出要求，小美网络公司将在30个工作日内向您寄送发票。发票信息和寄送信息以您提供的信息为准，由于信息不准确造成的后果由您独自承担。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.5
            服务期临近届满且双方达成一致需要继续使用小美客服系统服务的，您应至少在服务期满前30日完成续费款项的支付。如续费时小美网络公司对小美客服系统服务名称、价格进行了调整的，双方同意按照最新的产品/服务名称、价格进行续费。如您没有在约定的期间内完成续费支付的，小美网络公司将在服务期满后100日内保留您的使用数据，您应当自行对数据进行备份或导出，小美网络公司对此不承担任何责任；如您没有在此期间内完成续费款项的支付，则小美网络公司有权删除您的使用数据，由此造成的一切后果由您独自承担。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.6
            小美客服系统服务费用为按顺延年计费。如服务期未满您要求结束服务的，您同意按照
            <el-button link type="info" @click="click1">
              《小美客服系统终止服务流程规则》
            </el-button>
            的规定进行处理。若您在服务期内需要额外增购小美客服系统服务的，则增购部分的服务到期日与您购买部分的服务到期日相同，增购部分的服务费用按照您实际使用期间计费。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.7
            小美网络公司保留在您未按照约定支付全部费用之前不向您提供服务，或终止服务的权利；并且保留对您逾期支付行为追究法律责任的权利。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.8
            小美网络公司会定期或不定期推出优惠活动或政策，例如：一次性订购满一定期限或服务量，赠送一定服务内容、延长服务期限或提供直接折扣、发放免费代金券，特定节日特价/折扣，推荐码优惠等等形式；您充分了解小美客服系统的所有赠送服务项目和涉及价格的活动均为在正常服务价格之外的一次性特别优惠，优惠内容不包括赠送服务项目的修改、更新和维护费用，并且赠送服务项目不可折价冲抵服务费用、不可兑换现金。如赠送小美客服系统账户充值额的，需在实际支付部分消耗完毕后再消耗赠送部分。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.9
            您理解并同意，针对特定期限或数量的产品配置的优惠服务资源将不支持退订，如包年或包量优惠套餐，您需一次性使用，若您需退订已购买的优惠服务资源，您已经缴纳未消耗的费用将不予退还。若本服务条款与双方其他约定或小美客服系统服务规则冲突或不一致的，以本条约定为准。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.10 您理解并同意以下其他与费用相关的规则：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.1
            您如果通过支付预付款使用小美客服系统服务的，预付款对应的服务有效期为付款后的12个自然月，除本服务条款另有约定外，预付款不可退。您采购的小美客服系统服务需在您付款后的12个自然月内开通使用，逾期开通将无法使用，所支付的费用不予退还。若预付款不足抵扣或消耗完毕时，您应及时支付。针对需预付款用的具体服务项目，您的预付款月不足以抵扣当次结算应付服务费用的，小美网络公司有权在不足抵扣之日起，暂停向您提供小美客服系统的服务，因此造成的任何损失，由您自行承担。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.2
            小美客服系统智能云客服呼叫产品（包括但不限于电销版/标准版/专业版/旗舰版呼叫坐席）产生的通话费用由您与电信运营商自行结算，或者您需要另行购买标准纯云语音资源包/外呼机器人-语音资源包。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.3
            小美客服系统智能云客服智能机器人会话包/机器人会话条数产品，机器人会话条数（或机器人会话包内会话条数）仅限在相关具体订单约定有效服务期内使用，服务期届满后未使用的会话数将不可使用；若您续订会话条数包的，会话数不叠加。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.4
            小美客服系统智能云客服智能质检服务产品，仅限相关具体订单约定的有效服务期内使用，服务期届满的，剩余可使用质检量将清零。若您续订智能质检产品的，可使用检测量不叠加。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.5
            小美客服系统智能云客服短信产品，不同短信订单、短信包的使用期限及短信量独立计算，多个订单、短信包的使用期限不进行叠加。短信服务不支持退订、退款等，使用期满后相应短信服务不可用。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5.10.6
            小美客服系统智能云客服纯云语音资源包（话费），该服务内容由电信运营商提供，号码由电信运营商分配，小美客服系统不支持选号；小美客服系统仅作为渠道仅提供相关配套技术服务。您购买的纯云语音资源包内语音呼出时长仅限于相关具体订单约定的有效服务期内使用，服务期满则未使用的语音呼出时长将不可使用；若您续订纯云语音资源包的，语音呼出时长不叠加。
          </div>
          <div class="little margin-title">6、服务的变更、中止和终止</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.1
            小美网络公司始终在不断变更和改进小美客服系统服务。您理解并同意，小美网络公司可能会增加或删除部分功能或服务，也可能根据自身商业决策等原因暂停或彻底终止全部功能或服务，但小美网络公司将提前
            30
            天在小美客服系统网站或其他官方平台的适当位置公告重大变更的内容。您理解并同意，除非另有约定或法律规定，小美网络公司没有继续保留、导出或者返还用户数据的义务。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.2
            如有上述终止小美客服系统服务的情形发生，除法律有明确规定的情况外，小美网络公司有权不经您申请并直接为您办理退款，并对应清空您在小美客服系统账户内的余额、充值额、代金券等。前述退款的范围不包括您根据优惠折扣所获得的额外赠送费用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            6.3
            如您继续使用小美客服系统服务，则视为您接受上述变更。如您不接受上述变更，您有权继续按照变更前的内容和标准使用变更部分的小美客服系统服务直至该部分服务对应的有效订单下的预付款耗尽或停止使用小美客服系统服务。如您选择停止使用小美客服系统服务，小美网络公司应与您进行结算，并且您应将业务数据迁出。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            6.4
            经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者小美网络公司根据自身的判断，认为您的行为违反本服务条款内容或小美网络公司不时公布的使用规则等内容（包括但不限于违反法律法规规定、严重违反本服务条款等），则小美网络公司有权中止或终止向您提供服务，不退还您已支付的费用，并不承担任何责任。
          </div>
          <div class="little margin-title">7、责任承担与责任限制</div>
          <div class="content-text">
            <div class="short-space"></div>
            7.1
            小美客服系统服务承诺及服务不可用的补偿标准以小美客服系统各具体服务项目网页公布的服务等级协议为准。小美网络公司所提供的小美客服系统服务不符合约定的服务标准的，应当按服务等级协议或者双方另行的其他约定的方式给予您补偿。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            7.2
            因您违反本服务条款约定产生的责任和后果由您自行承担，若给小美网络公司或第三方造成损失的，您应当予以赔偿。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            7.3
            您未按本服务条款或双方其他约定履行付款义务的，小美网络公司除有权拒绝开通服务或无需另行通知而中止、终止服务外，还有权采取包括但不限于以下一种或多种措施：
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            7.3.1
            要求您依约定支付应付费用，并应按每日/千分之一的标准向小美网络公司支付违约金。若您逾期付款超过十日，小美网络公司有权无需另行通知您即可单方解除服务协议。
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            7.3.2
            如您的账户有未消耗的现金余额的，则小美网络公司有权无需另行通知您，而直接将您账户的现金余额用于抵扣拖欠款项和违约金。
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            7.3.3
            删除您基于使用小美客服系统服务而存储或产生的部分或全部信息和数据。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            7.4
            在任何情况下，对您因使用或不能使用小美客服系统服务所产生的任何间接的、偶然的、特殊的或惩罚性的损害及损失，包括预期利润的丧失、机会损失、声誉/商誉损害、贸易中断、商业信息的丢失等，小美网络公司不承担责任。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            7.5
            在任何情况下，小美网络公司的最大赔偿责任不超过您订购服务对应之服务费用总额。若本服务条款与双方其他约定或小美客服系统服务规则冲突或不一致的，以本条约定为准。
          </div>
          <div class="little margin-title">8、不可抗力和其他免责事由</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            8.1
            小美网络公司不保证小美客服系统服务毫无瑕疵，您同意：即使小美网络公司提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为小美客服系统违约。小美网络公司对您使用小美客服系统服务不作任何明示或暗示的保证，包括但不限于本服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途等。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            8.2
            您理解并同意，使用小美客服系统服务可能会影响第三方软件、应用程序或第三方服务的可用性。小美网络公司不保证小美客服系统服务将兼容任何第三方软件或者第三方服务。小美网络公司不提供与任何开源软件有关的或小美客服系统服务中可能使用的、集成的或与共同提供的第三方技术有关的任何保证。您同意只向第三方寻求有关第三方技术的全部保证。您知悉并理解，由此发生的服务不可用导致的任何损失，小美网络公司不承担任何责任。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            8.3
            小美客服系统服务不适用于服务出现故障或时间延误、或所提供的内容、数据或信息出现错误或不准确时，可能会导致人身伤亡、国家安全受损或严重的环境损害的情况，包括但不限于操作核能设施、飞机导航通信系统、空中交通管制系统、生命维持设备或者任何武器系统。您知悉并理解，您购买小美客服系统服务将不会用于前述的任意一种或多种系统，否则所产生的一切后果与责任将由您独自承担。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.4
            您理解并同意，因不可抗力使小美客服系统服务发生中断，不视为小美网络公司违约，若由此给您造成的损失小美网络公司将予以免责。不可抗力系指不能预见、不能避免并不能克服的客观情况，包括但不限于：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            8.4.1 天灾、疫情、战争、暴乱、法律法规或政府政策、指令的变更；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            8.4.2
            因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，亦视为不可抗力。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            8.5
            您理解并同意，因以下事由导致小美客服系统服务中断的，不视为小美网络公司违约，若由此给您造成的损失小美网络公司将予以免责：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            8.5.1
            小美网络公司在进行机房维护、设备检修、系统升级或优化时，需要中断服务；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            8.5.2
            由于您自身或第三方的原因导致的服务中断、终止，包括您操作不当、第三方产品或服务提供者的软硬件故障等。
          </div>
          <div class="little margin-title">9、信息安全与保密义务</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            9.1
            保护您的信息安全是小美网络公司的一项基本政策，小美网络公司会按照公布的《小美网路集团隐私政策》的规定保护您的相关信息。所以，小美网络公司不会公开或透露您的注册资料及采集、存储、上传到小美客服系统服务中的非公开信息，除非小美网络公司在诚信的基础上认为透露这些信息在以下几种情况是必要的：
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.1 事先获得您的授权；或
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.2
            在国家有关机关依法查询或调阅时，遵守有关法律规定或政策文件要求，向有关机关或第三方提供您的注册资料、您在小美客服系统服务器或者网页上发布、存储、传播或小美网络公司在小美客服系统服务中采集的信息内容及其发布时间、互联网地址或者域名等；或
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.3
            小美网络公司可能会与第三方合作向您提供小美客服系统的相关服务，在此情况下，如该第三方同意承担与小美网络公司同等的保护您信息安全的责任，则小美网络公司可将您的信息提供给该第三方；或
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.4 保持维护小美网络公司的知识产权和其他重要权利；或
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.5 在紧急情况下竭力维护您和社会大众的信息安全；或
          </div>
          <div class="content-text bold">
            <div class="long-space"></div>
            9.1.6 根据本服务条款相关规定或者小美网络公司认为必要的其他情况。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            9.2
            您可自行对您的信息内容进行上传、删除、更改等操作。小美网络公司建议您应谨慎进行前述操作，在删除信息之前进行备份。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            9.3
            您保证，您采集、存储、上传到小美客服系统服务中，或者由小美网络公司在小美客服系统服务采集的信息内容，为您依法收集、获取或产生的数据。您承诺上述所有数据已通过合法有效的方式取得相关数据主体的明示授权（包括告知相关主体其数据收集的目的、范围及使用方式等），并确保小美网络公司为提供小美客服系统服务对前述数据进行采集、上传、存储、分析和分发等任何处理均符合相关法律法规的要求，不存在任何违法、侵权或违反您与第三方的合同约定的情形。因您的数据的产生、收集、处理、使用等任何相关事项存在违反法律法规等情况而造成的全部结果及责任均由您自行承担，并且，小美网络公司有权随时全部或部分终止向您提供小美客服系统服务，以及要求您赔偿由此给小美网络公司造成的全部损失。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            9.4
            您对于因使用小美客服系统服务而了解或接触到的小美网络公司的商业秘密及其他机密和信息，相关技术、程序、资料、文档，协议内容、价款及及往来文件（以下统称“保密信息”）均应保守秘密；非经小美网络公司书面同意，不得向第三方泄露、给予或转让该保密信息。保密协议不因本服务条款的终止而终止。
          </div>
          <div class="little margin-title">10、出口管制与制裁合规</div>
          <div class="content-text">
            <div class="short-space"></div>
            您应遵守所有适用的由联合国、中国、美国及任何其他国家（地区）、国际组织所制定并执行的有关出口管制与经济贸易制裁方面的制裁决议、法律与法规（以下统称“出口管制与制裁法律法规”）。您承诺，您不是前述出口管制与制裁法律法规限制、制裁的对象，也未直接或间接为被限制、制裁的对象提供资金、商品或服务；非经相关主管机关许可，您及经您授权使用小美客服系统服务的个人或实体不会通过小美网络公司提供的产品或服务向被限制、制裁的对象提供受控的技术、软件或服务，或以任何方式使得小美网络公司违反出口管制与制裁法律法规。
          </div>
          <div class="little margin-title">11、知识产权</div>
          <div class="content-text">
            <div class="short-space"></div>
            11.1
            小美客服系统的专利、版权、设计等知识产权，以及与小美客服系统服务相关的所有信息内容，包括但不限于文字、图片、档案、资讯、资料、网站架构、页面设计均由小美网络公司依法享有知识产权。小美网络公司提供小美客服系统服务时所涉及到的内容包括但不限于：产品、技术、程序、资料、文档、界面设计、数据资料、账号等，其知识产权均归属于小美网络公司。
            <span class="bold">
              未经小美网络公司书面同意，您不能为任何营利或非营利性的目的擅自复制、剽窃、传播、仿造这些内容、或创造与该等内容有关的派生产品、或以转让、出租、抵押、处置、许可的方式授权第三方实施、利用和转让上述知识产权。您也不得逆向工程、反编译或试图以其他方式发现小美客服系统服务的任何源代码。
            </span>
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            11.2
            小美客服系统服务的名称、小美客服系统网站的Logo、“小美网络智慧企业”、“小美客服系统”等文字、图形以及小美网络公司的其他标志等，均为小美网络公司之商标或美术作品（以下统称“小美客服系统标识”）。未经小美网络公司事先书面同意，您不得将小美客服系统标识及小美网络公司享有相关权利的其他标识以任何方式使用或作其他处理，也不得向他人表明用户有权使用小美客服系统标识。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            11.3
            您应尊重小美网络公司和第三方的知识产权和其他合法权利/权益，并保证在发生侵犯前述权益的违法事件时，保护小美网络公司及其雇员、股东、合作伙伴等免于因该等事件受到影响或损失，小美网络公司保留在您侵犯小美网络公司及/或其他第三方的合法权利/权益时终止向您提供服务并不退还任何款项的权利。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            11.4
            小美网络公司尊重知识产权，在获得您同意的情况下，小美网络公司可能在小美客服系统网站及相关宣传材料内、在全球范围内、使用您和您产品的名称、Logo
            或商标用于宣传或推广用途。任何组织或个人认为小美客服系统网站的网页内容，可能侵犯其合法权益的，可向小美网络公司提出书面权利通知，小美网络公司将在收到知识产权权利人合格通知后依法尽快处理。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            11.5
            小美网络公司尊重知识产权并注重保护您享有的各项权利。在小美客服系统所含服务中，您可能需要通过发表文字、图形、音视频等各种方式向小美客服系统平台提供内容。在此情况下，您仍然享有此等内容的完整知识产权。您在提供内容时将授予小美网络公司一项永久的、全球性的、不可撤销的免费许可，允许小美网络公司使用、传播、复制、修改、再许可、翻译、创建衍生作品、出版及展示此等内容，但上述授权内容不包括您的业务数据和声明保留知识产权的内容。
          </div>
          <div class="little margin-title">12、适用的法律</div>
          <div class="content-text bold">
            <div class="short-space"></div>
            本服务条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。如出现因本服务条款引起的或与本服务条款有关的任何纠纷的，您与小美网络公司应友好协商解决，若协商不成，您同意将纠纷提交中国国际经济贸易仲裁委员会仲裁解决，并由3名仲裁员进行审理。仲裁裁决是终局的，对双方均有约束力。若单项条款与本服务条款在管辖约定内容上存在冲突，则在单项条款约束范围内应以单项条款为准。
          </div>
          <div class="little margin-title">13、其他</div>
          <div class="content-text">
            <div class="short-space"></div>
            13.1
            本服务条款自发布之日起实施，并构成您和小美网络公司之间的共识。小美网络公司不行使、未能及时行使或者未充分行使本服务条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响小美网络公司在将来行使该权利。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            13.2
            如本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效且具有约束力，小美网络公司及用户均应尽力使该条款设定的本意得到实现。
          </div>
          <div class="content-text bold">
            <div class="short-space"></div>
            13.3
            小美网络公司有权以提前30天在小美客服系统网站公布或给您发站内通知或书面通知的方式将本服务条款的权利义务全部或者部分转移给关联公司。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            13.4 本服务条款中的标题仅为方便而设，不作为解释本服务条款的依据。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            13.5
            如果您对本服务条款内容有任何疑问，请发送邮件至我们的客服邮箱：xiaomeikeji@mail.chat5188.com。
          </div>
        </div>
        <div class="box" v-if="index === 2">
          <div class="content-title">小美客服系统服务等级协议</div>
          <div class="little margin-title">1、服务范围</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统云客服（简称“小美客服系统”）为用户提供在线客服功能的云服务。用户可以调用小美客服系统提供的API接口通过网络进行访客端消息的发送和接收；用户可以登录小美客服系统提供的客服工作台，进行客服端消息的发送和接收，以及工单工作流处理；用户可以登录小美客服系统提供的管理后台，进行管理、设置的相关操作。小美客服系统用户的客服座席数量支持弹性扩容，并提供按座席数量及拓展功能付费的结算功能。
          </div>
          <div class="little margin-title">2、服务等级指标</div>
          <div class="content-text">
            <div class="short-space"></div>
            （1）数据持久性：不低于99.99999999%，数据持久性按服务周期统计，一个服务周期为一个自然月，如不满一个月不计算为一个服务周期。即，用户每月储存在小美客服系统的对象的数据持久性为99.99999999%，意为存储在小美客服系统上一百亿条消息记录，每月最多只有1条发生数据丢失的可能性。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （2）服务可用性：计算公式为
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            “[1 – (服务月内不可用分钟数/每月总分钟数)] * 100%”。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            服务可用性不低于99.9%，小美客服系统可用性按服务周期统计，一个服务周期为一个自然月，如不满一个月不计算为一个服务周期。统计的业务单元为每个客户的所有应用，时间单位为分钟。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            不可用时间：小美客服系统所提供的服务在连续5分钟或更长时间不可使用方计为不可用时间，不可使用的服务时间低于5分钟的，不计入不可用时间。小美客服系统不可用时间不包括日常系统维护时间、由用户原因、第三方原因或不可抗力导致的不可用时间。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统服务不可用包含以下2种情况：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            A: 用户正常业务请求返回错误码；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            B: 因小美客服系统网络原因导致的服务不可达。
          </div>
          <div class="little margin-title">3、数据可销毁性</div>
          <div class="content-text">
            <div class="short-space"></div>
            在用户主动删除数据或用户服务期满后需要销毁数据的，小美客服系统将清除对应物理服务器上磁盘和内存数据，使得数据无法恢复。
          </div>
          <div class="little margin-title">4、数据可迁移性</div>
          <div class="content-text">
            <div class="short-space"></div>
            用户使用小美客服系统所产生的数据，含消息、工单、用户信息、企业及客服信息，可使用小美客服系统接口获取相关数据内容，并根据需要自行保存或迁出。
          </div>
          <div class="little margin-title">5、数据私密性</div>
          <div class="content-text">
            <div class="short-space"></div>
            用户开通小美客服系统服务后，小美客服系统为用户分配AppKey和Secret安全加密对，从小美客服系统访问接口上进行用户所属应用数据的隔离，保障用户数据的私密性。
          </div>
          <div class="little margin-title">6、数据可审查性</div>
          <div class="content-text">
            <div class="short-space"></div>
            依据现行法律法规或根据政府监管部门监管、安全合规、审计或取证调查等原因的需要，在符合流程和手续完备的情况下，小美客服系统可以提供用户所使用服务的相关信息，包括关键组件的运行日志、用户登录日志等信息。
          </div>
          <div class="little margin-title">7、服务功能</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统是小美网络旗下一款专注于解决企业与客户沟通的客服系统产品，致力于为企
            业客户提升客服工作效率、提高客户满意度、通过智能化和大数据的方式降低企业的管理成本。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统提供以在线客服为核心的沟通方式；将多种渠道来源整合，提供统一的客服工作台；提供多种灵活的API接口，可以与任意CRM系统对接。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统提供智能问答机器人功能和知识库及相应的管理功能，可以实现无人值守自动接待访客；提供工单工作流功能。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统提供的所有具体功能请详见产品使用手册。小美客服系统提供的所有API接口说明和对接指南请详见相关技术文档。
          </div>
          <div class="little margin-title">8、服务资源调度能力</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统为用户提供在线客服功能的云服务，并支持用户通过网络进行访客及客服消息的发送和接收。小美客服系统对用户的接入终端数量不设置上限，根据需要，终端并发容量将实时动态扩展。随着访问量的增长，小美客服系统后台处理能力会自动扩展，无需用户干预。
          </div>
          <div class="little margin-title">9、故障恢复能力</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统为付费用户的云服务提供7×24小时的运行维护，并以电话报障方式提供技术支持，具备完善的故障监控、自动告警、快速定位、快速恢复等一系列故障应急响应机制。
          </div>
          <div class="little margin-title">10、网络接入性能</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统对用户不限制公网出口带宽，采用BGP多线接入，保障用户的网络接入质量。
          </div>
          <div class="little margin-title">11、服务补偿条款</div>
          <div class="content-text">
            <div class="short-space"></div>
            （1） 补偿范围
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            因小美客服系统设备故障、设计缺陷或操作不当导致用户所购买的小美客服系统服务无法正常使用，小美客服系统将对不可用时间进行补偿，但不包括以下原因所导致的服务不可用时间：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （a）
            小美客服系统预先通知用户后进行系统维护所引起的，包括割接、维修、升级和模拟故障演练；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （b） 任何小美客服系统所属设备以外的网络、设备故障或配置调整引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （c） 用户的应用程序或数据信息受到黑客攻击而引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （d）
            用户维护不当或保密不当致使数据、口令、密码等丢失或泄漏所引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （e） 用户自行升级操作系统所引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （f） 用户的应用程序或安装活动所引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （g） 用户的疏忽或由用户授权的操作所引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （h） 不可抗力以及意外事件引起的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （i） 其他非小美客服系统原因所造成的不可用。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            （2） 补偿方案
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统对用户存在故障的每个应用按不可用时间的10倍补偿，即
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            补偿金额 = 发生故障的应用故障前24小时平均每分钟费用×不可用时间×10；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            其中:
          </div>
          <div class="content-text">
            <div class="long-space-2"></div>
            （a）
            补偿只针对使用小美客服系统服务已产生费用的用户，用于抵扣续费或升级的费用，不折算现金返还；
          </div>
          <div class="content-text">
            <div class="long-space-2"></div>
            （b） 不可用时间 = 故障解决时间-故障开始时间，不可用时间按分钟计算；
          </div>
          <div class="content-text">
            <div class="long-space-2"></div>
            （c） 如果用户没有使用到24小时，按实际使用时长计算平均每分钟费用；
          </div>
          <div class="content-text">
            <div class="long-space-2"></div>
            （d） 补偿总金额不超过当月费用金额（即年费/12）。
          </div>
          <div class="little margin-title">12、其他</div>
          <div class="content-text">
            <div class="short-space"></div>
            小美客服系统有权根据变化适时对本服务等级协议部分服务指标作出调整，并及时发布公告或发送邮件或书面通知向用户提示修改内容。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            如果用户对本条款内容有任何疑问，请发送邮件至我们的客服邮箱：xiaomeikeji@mail.chat5188.com。
          </div>
        </div>
        <div class="box" v-if="index === 3">
          <div class="content-title">【小美客服系统】服务条款</div>
          <div class="little margin-title">关于</div>
          <div class="content-text">
            <div class="short-space"></div>
            本服务条款是您与【东莞七象智能科技有限公司】（下称本公司）之间的协议。【小美客服系统】（下称本服务）是由【东莞七象智能科技有限公司】提供的互联网软件服务。在本服务创建账户并使用之前，您必须首先同意此条款。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            此条款在必要时将进行修订，且毋须另行通知。修订后的条款一旦在网页上公布即有效代替原来的服务条款。
          </div>
          <div class="little margin-title">账户</div>
          <div class="content-text">
            <div class="short-space"></div>
            您同意并理解您有责任将您用于获得服务的任何帐户相关的密码保密。您对您账户下发生的所有的行为和内容负责。您同意对自己的帐号和密码进行妥善保管，对于因密码泄露所致的损失，由您自行承担。如您发现有他人冒用或盗用您的帐号及密码或任何其他未经合法授权之情形时，应立即以有效方式通知本公司，要求本公司暂停相关服务。同时，您理解本公司对其的请求采取行动需要合理期限，在本公司采取措施前，本公司对已执行的指令及所导致的您的损失不承担任何责任。
          </div>
          <div class="little margin-title">付款</div>
          <div class="content-text">
            <div class="short-space"></div>
            您注册本服务即表示您同意支付其中的所有费用。服务费用标准已经在您订购页面进行了明示，您可以选择具体服务类型并按照明示的价格进行支付。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            除非因为本公司方面的问题导致本服务无法正常使用（无法正常使用特指本服务的核心服务无法使用且无法修复或本公司终止提供本服务），您支付的有关本服务的所有费用均不能退款。
          </div>
          <div class="little margin-title">内容和版权</div>
          <div class="content-text">
            <div class="short-space"></div>
            本服务的所有权和运作权、解释权归本公司。任何经由本公司产品或平台以上载、张贴、发送信息、电子邮件或任何其他方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息、用户的登记资料或其他资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者承担责任。本公司无法控制经由本服务传送之内容，也无法对用户的使用行为进行全面控制，因此不保证内容的合法性、正确性、完整性、真实性或品质；但在任何情况下，本公司有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本服务的全部或部分，保存有关记录，并向有关机关报告。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            本服务的外观设计与专利归本公司所有。未经本公司书面许可，您不能复制、拷贝，或者重用任何部分代码和外观设计。
          </div>
          <div class="little margin-title">隐私政策</div>
          <div class="content-text">
            <div class="short-space"></div>
            使用本服务，即表示您同意本公司合法收集和使用有关您及您所使用本服务的技术性或诊断性信息。收集到的这些信息将用于改进网页的内容，提升我们的服务品质。本公司不会将您的信息和内容分享或出售给其他的组织，经过您同意或司法、行政机关等有权机构要求的除外。
          </div>
          <div class="little margin-title">重要提示</div>
          <div class="content-text">
            <div class="short-space"></div>
            在使用本服务期间，您不得危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得制作、复制、存储、发布、查阅和传播下列信息：
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            损害国家荣誉和利益的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            煽动民族仇恨、民族歧视，破坏民族团结的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            破坏国家宗教政策，宣扬邪教和封建迷信的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            散布谣言，扰乱社会秩序，破坏社会稳定的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            侮辱或者诽谤他人，侵害他人合法权益的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            以非法民间组织名义活动的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            含有法律、行政法规禁止的其它内容的；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            不得用任何不正当手段损害我们及其他用户的利益及声誉。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            如您有违反以上承诺的行为，则此等行为导致的后果与本公司无关。如有确切记录证明您违反上述承诺的，本公司可以解除与您的服务合同关系并要求相应的赔偿，本公司对违反规定的可以暂停或终止其服务，并协助互联网、公安等有关行政机关等进行追索和查处。
          </div>
          <div class="little margin-title">
            服务及服务条款的变更、中止和终止
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            本服务始终在不断变更和改进服务，可能会增加或删除部分功能或服务，也可能暂停或彻底停止某项服务。如果服务条款、内容发生重大变更，本公司会提前在网站或产品其他平台的适当位置公告重大变更的内容。如您继续使用，则视为您接受上述变更。如您不接受上述变更，您有权停止使用本服务。
          </div>
          <div class="little margin-title">不可抗力条款</div>
          <div class="content-text">
            <div class="short-space"></div>
            本公司对不可抗力导致的损失不承担责任。本服务条款所指不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、光缆等基础设施损坏、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
          </div>
          <div class="little margin-title">终止</div>
          <div class="content-text">
            <div class="short-space"></div>
            您可随时终止您的帐户和/或停用本服务。您在服务终止前支付的任何费用均不能退还（除本服务条款另有规定的除外）。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            本公司可在特定情况下，不需事先通知，可终止或暂停你所使用的账户和服务。此情况的原因包括但不限于：
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            违反本服务条款；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            您要求取消或终止您的帐户；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            需要听从法庭传票、法律命令或遵循法律程序；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            突发的自然灾害导致的问题；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            战争、军事行动、暴动或武装叛乱；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            您没有支付与本服务相关的费用；
          </div>
          <div class="content-text">
            <div class="long-space-1"></div>
            一旦您的帐户被终止，您将无法正常使用本服务。
          </div>
        </div>
        <div class="box" v-if="index === 4">
          <div class="content-title">信息安全承诺书</div>
          <div class="content-text">
            <div class="short-space"></div>
            为切实配合通信运营商强化语音业务的安全使用和规范管理，维护国家安全和社会稳定，保障社会公众利益和公民合法权益，保障其它客户的合法权益，根据国家相关法律法规对信息安全管理的要求，甲方保证通过乙方小美客服系统云客服平台使用通信运营商的云中继、语音专线、语音平台等语音通信服务时遵守以下各项规定：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            一、甲方使用运营商语音通信的业务用途皆为合法合规用途，每天的呼叫时段【8:00-22:00】，单号码呼叫频次【200】次/日。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            二、遵守国家有关法律、行政法规和管理规章，严格执行信息安全管理规定。凡被发现相关号码为冒用或伪造身份证照申请、违法使用、违规外呼、呼叫频次异常、超约定用途使用、转租转售、被公安机关通报以及用户就上述问题投诉较多等情况的，核实确认后，贵方和通信运营商有权终止为甲方提供服务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            三、承诺书适用的语音服务范围包括但不限于普通固定电话、移动电话、语音专线、云中继、呼叫中心直连业务等语音接入类业务。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            四、甲方承诺对发送信息的真实性、准确性、合法性负责，发布的内容必须严格遵守《中华人民共和国电信条例》有关规定，不利用通信运营商通信服务发布和传播有害信息，不得散发传播违法、不健康反动等信息，不得发布任何含有下列内容之一（即“九不准”及“六不许”内容）的信息：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            “九不准”，即：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            1. 反对宪法所确定的基本原则的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            3. 损害国家荣誉和利益的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            4. 煽动民族仇恨、民族歧视，破坏民族团结的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            5. 破坏国家宗教政策，宣扬邪教和封建迷信的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            6. 散步谣言，扰乱社会秩序，破坏社会稳定的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            7. 散步淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            8. 侮辱或者诽谤他人，侵害他们合法权益的;
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            9. 含有法律、行政法规禁止的其他内容的。
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            “六不许”，即：
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            10. 决不允许在群众中散布违背党的理论和路线方针政策的意见；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            11. 决不允许公开发表同中央的决定相违背的言论；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            12. 决不允许对中央的决策部署阳奉阴违；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            13. 决不允许编造、传播政治谣言及丑化党和国家形象的言论；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            14. 决不允许以任何形式泄露党和国家的秘密；
          </div>
          <div class="content-text">
            <div class="long-space"></div>
            15. 决不允许参与各种非法组织和非法活动。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            五、在使用通信运营商通信服务的情况下，甲方承诺建立有效的信息安全管理制度和技术保障措施，确保备份呼叫内容录音文件，并接受贵方、通信运营商及国家相关主管部门的管理、监督和检查，为相关主管部门提供技术支持。甲方同意贵方对甲方使用通信服务进行呼叫内容的录音和备份。如法律或国家主管部门对信息安全管理有新要求，甲方将无条件配合落实相关整改举措，直至符合相关法律及政策文件要求。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            六、甲方承诺使用通信运营商通信服务时，进行外呼业务的主叫号码为通信运营商分配的号码，不隐藏、变更或转租、转售号码。不违规经营、不变更合同约定用途，不隐藏、变更或转租、转售主叫号码，不开展无特定主被叫的话务批发业务，不私自转接国际来话，不通过技术手段为非法VoIP、改号电话、网络电话（PC软件/APP等）提供语音落地，不采取自动语音群呼方式进行外呼。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            七、甲方承诺不开展诈骗、骚扰、涉黄、涉恐、危害国家安全和社会稳定的违法活动。如因此引发的社会群众投诉和违法责任将由甲方全部承担，甲方同意贵方和通信运营商终止提供服务（包括但不限于在不通知我单位的情况下终止服务），给乙方、乙方关联公司和通信运营商造成的全部损失，甲方负责全额赔偿。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            八、甲方如出现任何违反上述承诺的情况，将依据与贵方签订的合同承担违约责任（包括但不限于在不通知甲方的情况下暂停服务，直至单方面终止合同），情节严重的，接受有关部门的处理（包括但不限于限期整改、公开曝光，并承担相应的法律责任等）。相应责任后果全部由甲方自行承担。
          </div>
        </div>
        <div class="box" v-if="index === 5">
          <div class="content-title">用户入网责任书</div>
          <div class="content-text">
            <div class="short-space"></div>
            根据中华人民共和国工业和信息化部、公安部等部委关于加强在公共信息服务中传播信息管理的有关规定和相关精神，本单位郑重承诺遵守本责任书的有关条款，如有违反本责任书有关条款的行为，本单位承担由此带来的一切民事、行政和刑事责任。
          </div>
          <div class="content-text">
            一、
            本单位保证不利用网络危害国家安全、泄露国家秘密，不侵犯国家的、社会的、集体的利益和第三方的合法权益，不从事违法犯罪活动、妨碍社会治安的活动。
          </div>
          <div class="content-text">
            二、 本单位承诺不通过互联网制作、复制、查阅和传播下列信息：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.煽动抗拒、破坏宪法和法律、行政法规实施的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            2.煽动颠覆国家主权，推翻社会主义制度的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            3.煽动分裂国家、破坏国家统一的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            4.煽动民族仇恨、民族歧视，破坏民族团结的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            5.捏造或者歪曲事实，散布谣言，扰乱社会秩序的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            6.宣传封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖犯罪的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            7.公然侮辱他人或者捏造事实诽谤他人的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            8.损害国家机关信誉的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            9.其它违反宪法和法律、行政法规的。
          </div>
          <div class="content-text">
            三、
            本单位承诺集中对以下十三个方面的低俗内容在本公司网站内进行核查清理：
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            1.表现或隐晦表现性行为、令人产生性联想、具有挑逗性或者污辱性的内容；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            2.对人体性部位的直接暴露和描写；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            3.对性行为、性过程、性方式的描述或者带有性暗示、性挑逗的语言；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            4.对性部位描述、暴露，或者只用很小遮盖物的内容；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            5.全身或者隐私部位未着衣物，仅用肢体掩盖隐私部位的内容；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            6.带有侵犯个人隐私性质的走光、偷拍、漏点等内容；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            7.以挑逗性标题吸引点击的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            8.相关部门禁止传播的色情、低俗小说，音视频内容，包括一些电影的删节片断；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            9.一夜情、换妻、SM等不正当交友信息；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            10.情色动漫；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            11.宣扬血腥暴力、恶意谩骂、侮辱他人等内容；
          </div>
          <div class="content-text">
            <div class="short-space"></div>

            12.非法性用品广告和性病治疗广告；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            13.未经他人允许或利用“人肉搜索”恶意传播他人隐私信息。
          </div>
          <div class="content-text">
            四、
            本单位承诺不得从事下列危害计算机信息网络安全的活动，包括但不限于：
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            1.未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            2.未经允许，对计算机信息网络功能进行删除、修改或者增加的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            3.未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            4.故意制作、传播计算机病毒等破坏性程序的；
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            5.其他危害计算机信息网络安全的。
          </div>
          <div class="content-text">
            五、
            本单位承诺在INTERNET上播发的信息内容是经当地宣传管理部门批准的内容；不得擅自下载境外INTERNET的信息在国内站址上发布，设立24小时待命的信息内容管理人，以便随时接受来自监督机构和接入机构的内容清理要求。
          </div>
          <div class="content-text">
            六、
            本单位对服务器内、网站内及经过加速的信息内容不定期自查，有交互性栏目的必须做到先审后发，发现问题立即处理并保存记录同时第一时间通知有关部门，切实落实信息安全责任制，加强对员工的信息安全教育工作。
          </div>
          <div class="content-text">
            七、
            根据《计算机信息网络国际联网安全保护管理办法》（公安部第33号令）中的第十二条规定和二十三条规定，本单位承诺网络正式连通之日起三十日内办理公安备案手续。如不履行备案职责的，将面临由公安机关给予的警告或者停机整顿不超过六个月的处罚。
          </div>
          <div class="content-text">
            八、
            本单位承诺对于由服务商分配的IP地址，本单位只有使用权，不得将获取的接入服务、IP地址等资源转让给其他单位或第三方企业使用，业务终止时IP地址使用权自动取消。在出现违法行为时及时提供真实有效的用户信息，以配合相关部门的调查取证工作。
          </div>
          <div class="content-text">
            九、
            根据《中华人民共和国网络安全法》国家实行网络安全等级保护制度。本单位承诺按照网络安全等级保护制度的要求，采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月。
          </div>
          <div class="content-text">
            十、
            根据《中华人民共和国网络安全法》第五十四条，为避免和减轻网络安全可能造成的危害，东莞七象智能科技有限公司及其分公司、子公司会及时以邮件、电话等方式发布网络安全风险预警，邮件通知在发送成功后视为已送达。
          </div>
          <div class="content-text">
            十一、根据《中华人民共和国网络安全法》中的第二十四条要求，本单位承诺签订协议或者确认使用服务时完成真实性身份验证。
          </div>
          <div class="content-text">
            十二、根据《中华人民共和国网络安全法》第二十七条要求，本单位承诺遵守有关规定，不从事非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；不得提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；明知他人从事危害网络安全的活动的，不得为其提供技术支持、 广告推广、支付结算等帮助。
          </div>
          <div class="content-text">
            <div class="short-space"></div>
            本单位已认真阅读了责任书的全部内容，并同意遵守其所有规定，如违反规定，愿根据法律规定承担相应责任。东莞七象智能科技有限公司及其分公司、子公司有权暂停直至终止提供网络服务、终止与本单位的合作，且不构成违约；同时，东莞七象智能科技有限公司及其分公司、子公司有权追究本责任单位法律责任。
          </div>
          <table border="1">
            <tr>
              <th colspan="4">客户基本情况表</th>
            </tr>
            <tr>
              <td>1.公司名称</td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>2.通讯地址</td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>3.客户联系人</td>
              <td><div class="table-empit"></div></td>
              <td>4.联系人邮箱地址</td>
              <td><div class="table-empit"></div></td>
            </tr>
            <tr>
              <td>5.联系人座机</td>
              <td></td>
              <td>6.联系人手机</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="table-long-side">
                  7. 客户联系人证件号码（身份证/护照/通行证）
                </div>
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                <div class="table-long-side">
                  8.统一社会信用代码 （组织机构代码）
                </div>
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                <div class="table-long-side">9.ICP证号/ICP备案号</div>
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>10.接入域名列表</td>
              <td colspan="3"></td>
            </tr>
          </table>
          <div class="table">
            *郑重提示：内容与实际不符，需承担相应法律责任（以上信息全部为必填项，如有变动请及时更新）
          </div>
          <div class="end-div">
            <div class="end-text">用户代表（签字）：</div>
            <div class="end-text">用户单位（盖章）：</div>
            <div class="end-text">
              日期：&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftList: [
        { label: "小美客服系统终止服务流程规则" },
        { label: "小美客服系统服务条款" },
        { label: "小美客服系统服务等级协议" },
        { label: "小美客服系统注册协议" },
        { label: "信息安全承诺书" },
        { label: "用户入网责任书" },
      ],
      index: 0,
    };
  },
  methods: {
    menuItemClick(val) {
      this.index = val;
    },
    // 《小美客服系统终止服务流程规则》
    click1() {
      this.menuItemClick(0);
    },
    // go(value) {
    //   document.getElementById(value).scrollIntoView();
    // },
  },
};
</script>

<style scoped lang="less">
// .question {
//   background-color: #f4f7ff;
//   padding-top: 80px;
// }
/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 15px; /* 设置滚动条宽度 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑块背景颜色 */
  border-radius: 4px; /* 设置滑块圆角 */
}

/* 滚动条轨道hover状态样式 */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1; /* 设置轨道hover状态时的背景颜色 */
}

/* 滚动条滑块hover状态样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滑块hover状态时的背景颜色 */
}
.meun {
  float: left;
  background-color: #304fae;
  height: 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
    height: auto;
  }
}
.end-box-agr {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  // display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}
.end-box {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}

::v-deep .el-menu {
  border: none;
  padding-left: 0px;
}

::v-deep .el-menu-item {
  height: 70px;
  color: #fff;
  line-height: 70px;
  font-size: 20px;

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

::v-deep .el-menu-item.is-active {
  background-color: #fff !important;
  color: #020f3b;
}

::v-deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #020f3b;
}

.content {
  width: 1080px;
  height: 706px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 3px;
  }
}
.box {
  width: 100%;
  height: 600px;
  overflow: auto;
  padding-left: 20px;
}

.content-title {
  color: #021b64;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (max-width: 700px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.little {
  color: #021b64;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;

  @media screen and (max-width: 700px) {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.margin-title {
  margin-top: 8px;
}

.content-text {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;

  line-height: 30px;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}
.bold {
  font-weight: bold;
}
.el-button {
  font-size: 18px;
  font-weight: 400;
}
.short-space {
  display: inline-block;
  width: 32px;
  height: 10px;
}
.long-space {
  display: inline-block;
  width: 52px;
  height: 10px;
}
.long-space-1 {
  display: inline-block;
  width: 47px;
  height: 10px;
}
.long-space-2 {
  display: inline-block;
  width: 36px;
  height: 10px;
}
.shore-space-between {
  display: inline-block;
  height: 10px;
  width: 10px;
}
.table-long-side {
  width: 200px;
}
.table-empit {
  width: 245px;
  height: 10px;
}
table {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
  margin-top: 20px;
}
td,
th {
  padding: 10px 5px;
}
.table {
  color: #021b64;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
}
.end-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #021b64;
  font-size: 18px;
  opacity: 70%;
}
.end-text {
  width: 400px;
  padding: 5px 0;
}
</style>
